import { FormEvent } from 'react';
import prescriber from '../icons/prescriber.svg';
import patient from '../icons/patient.svg';
import {
  faEnvelopeOpenText,
  faUserFriends,
  faUserLock,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from 'hooks/useRedux';
import { ConfigurableHyperLinkSelector } from 'state/hyperlink/hyperlink.selectors';
import './ProviderInstructionViewModal.scss';
import Card from '../components/Card';
import Wrapper from '../components/Wrapper';

interface ProviderInstructionViewModalProps {
  showButton?: boolean;
  onClick?: (e: FormEvent) => void;
}
const ProviderInstructionViewModal = ({
  showButton,
  onClick,
}: ProviderInstructionViewModalProps) => {
  const hyperlinks = useAppSelector(ConfigurableHyperLinkSelector);

  return (
    <div className="modal-bg-rems d-flex lh-1">
      <div className="container-fluid px-2 px-md-3 ">
        <div className="row p-2 p-md-3 gap-2 gap-md-3 modal-main-section-text">
          <Wrapper className="text-center text-white bg-warning justify-content-center align-items-center p-2 p-md-3">
            <h1 className="fw-bold my-auto modal-h1">
              Welcome to the XYWAV and XYREM REMS ePrescription Registration Process
            </h1>
          </Wrapper>
          <Wrapper className="bg-white p-3">
            <div className="row modal-main-section-text">
              <div>
                In order to electronically prescribe XYWAV and XYREM securely, <b>first</b> you and
                your patient <b>must</b> be enrolled in the XYWAV and XYREM REMS.
              </div>
            </div>
            <div className="row py-3 row-cols-sm-1 gap-3 row-cols-md-2">
              <div className="col">
                <div className="pb-1">
                  <b>Next</b>, you must complete a DEA-compliant onboarding process, known as the
                  Electronic Prescribing for Controlled Substances (EPCS)
                </div>
                <div>
                  <p
                    style={{ fontSize: '13px', color: '#675D58' }}
                    className="lh-1"
                  >
                    By clicking the link to enroll yourself or your patient, you will leave the EPCS
                    platform and need to reopen this page in order to continue the onboarding
                    process
                  </p>
                </div>
              </div>
              <div className="row row-cols-1 gap-3 gap-sm-0 row-cols-sm-2 row-cols-md-1 gap-md-3   ">
                <div className="col d-flex align-items-center justify-content-start">
                  <div className="pe-3">
                    <img src={prescriber} />
                  </div>
                  <div
                    style={{ fontSize: '16px' }}
                    className="lh-1"
                  >
                    Complete Prescriber REMS Certification by{' '}
                    <a
                      href={hyperlinks['configurable_hyperlink_1'] || '/'}
                      className="underline text-black"
                    >
                      clicking here
                    </a>
                  </div>
                </div>
                <div
                  className="col d-flex align-items-center"
                  style={{ fontSize: '16px' }}
                >
                  <div className="pe-3">
                    <img src={patient} />
                  </div>
                  <div className="lh-1">
                    Complete Patient REMS Enrollment by{' '}
                    <a
                      href={hyperlinks['configurable_hyperlink_2'] || '/'}
                      className="underline text-black"
                    >
                      clicking here
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
          <Wrapper
            bgColor={'#EDF1FD'}
            className="pb-3"
          >
            <div className="row text-center ">
              <h1 className="fw-bold modal-h1 pb-2 pb-md-3">
                What to expect during the Electronic Prescribing for Controlled Substances (EPCS)
                process.
              </h1>
            </div>
            <div className="container-fluid">
              <div className="row row-cols-1 row-cols-sm-2 g-2 g-md-3">
                <Card
                  icon={faEnvelopeOpenText}
                  titleClassName="text-warning fw-bold modal-h2"
                  title="Initial Onboarding"
                  header="Step 1"
                >
                  Complete the registration form with accurate information. Upon submission, you
                  will receive an EPCS Invite email for Identity Proofing (IDP).
                </Card>
                <Card
                  icon={faUserShield}
                  titleClassName="text-warning fw-bold modal-h2"
                  title="Identity Proofing"
                  header="Step 2"
                >
                  Complete identity proofing (IDP) via DrFirst's partnership with Experian, and bind
                  two-factor authentication (TFA) credentials to your account.
                </Card>
                <Card
                  icon={faUserLock}
                  titleClassName="text-warning fw-bold modal-h2"
                  title="EPCS Registration"
                  header="Step 3"
                >
                  Enter your Experian Transaction Number provided to you via text message or USPS
                  from Experian into the EPCS system. <br />
                  <br />
                  <b>
                    Receipt of the letter containing the Experian Transaction Number may take up to
                    7 days.
                  </b>
                  <br />
                  <br />
                  Login using the newly created credentials to continue once you receive the letter.
                  If you do not receive the letter after 7 days,{' '}
                  <a
                    className="text-black"
                    href="https://drfirsthelp1649968111jazz.zendesk.com"
                    target="_blank"
                  >
                    chat with us
                  </a>{' '}
                  or contact support at (855) 863-1355.
                </Card>
                <Card
                  titleClassName="text-warning fw-bold modal-h2"
                  icon={faUserFriends}
                  title="Logical Access Control (LAC)"
                  header="Step 4"
                >
                  The DEA requires one additional individual to grant you "access control" to
                  electronically prescribe controlled substances. <br /> <br />
                  Invite a practice administrator or equivalent person to complete this step with
                  you.
                </Card>
              </div>
            </div>
            {showButton && (
              <div className="container d-flex justify-content-center pt-2 pt-md-3">
                <button
                  className="btn btn-primary btn-lg rounded-pill fw-bold"
                  onClick={onClick}
                  style={{ width: '266px' }}
                >
                  Get started
                </button>
              </div>
            )}
          </Wrapper>
        </div>
      </div>
    </div>
  );
};

export default ProviderInstructionViewModal;
