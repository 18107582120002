/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import axios from 'axios';
import { authActions } from '../state/auth';
import store from '../state/store';
import { workflowActions } from '../state/workflow';

const API_URL = process.env.REACT_APP_API_URL;

function encodeUriQuery(val, pctEncodeSpaces) {
  return encodeURIComponent(val)
    .replace(/%40/gi, '@')
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%3B/gi, ';')
    .replace(/%20/g, pctEncodeSpaces ? '%20' : '+');
}

function encodeUriSegment(val) {
  return encodeUriQuery(val, true)
    .replace(/%26/gi, '&')
    .replace(/%3D/gi, '=')
    .replace(/%2B/gi, '+');
}

class Service {
  constructor() {
    const service = axios.create();

    this.url = '';
    this.urlParams = {};
    this.service = service;
    this.service.interceptors.response.use(Service.handleSuccess, this.handleError);
    this.service.interceptors.request.use(this.setHeaders);
    this.subscriptionError = null;
  }

  setHeaders = (config) => {
    const token = this.getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  };

  getToken = () => {
    return store.getState().auth.access_token;
  };

  getPath = (params) => {
    const urlParams = (this.urlParams = Object.create(null));
    let url = API_URL + this.url;
    url.split(/\W/).forEach(function (param) {
      if (
        !new RegExp('^\\d+$').test(param) &&
        param &&
        new RegExp('(^|[^\\\\]):' + param + '(\\W|$)').test(url)
      ) {
        urlParams[param] = {
          isQueryParamValue: new RegExp('\\?.*=:' + param + '(?:\\W|$)').test(url),
        };
      }
    });
    url = url.replace(/\\:/g, ':');

    params = params || {};

    for (const urlParam in urlParams) {
      const paramInfo = urlParams[urlParam];
      let encodedVal;
      const val = Object.prototype.hasOwnProperty.call(params, urlParam) ? params[urlParam] : null;
      if (val !== null && val !== undefined) {
        if (paramInfo.isQueryParamValue) {
          encodedVal = encodeUriQuery(val, true);
        } else {
          encodedVal = encodeUriSegment(val);
        }
        url = url.replace(new RegExp(':' + urlParam + '(\\W|$)', 'g'), function (match, p1) {
          return encodedVal + p1;
        });
      } else {
        url = url.replace(
          new RegExp('(/?):' + urlParam + '(\\W|$)', 'g'),
          function (match, leadingSlashes, tail) {
            if (tail.charAt(0) === '/') {
              return tail;
            } else {
              return leadingSlashes + tail;
            }
          },
        );
      }
    }
    return url;
  };

  static handleSuccess(response) {
    store.dispatch(workflowActions.setError(false));

    return response;
  }

  handleError = async (error) => {
    /*
           When overriding this methods make sure to return Promise.reject(error);
           To prevent app crash.

           Also define new local interceptor in the class that inherits
        */

    const originalRequest = error.config;
    const error_code = error.response?.status;

    switch (error_code) {
      case 401:
        if (originalRequest.again) {
          break;
        } else {
          originalRequest.again = true;
        }

        await store.dispatch(authActions.loggingIn({}));
        this.service
          .request(originalRequest)
          .then((res) => {
            originalRequest.success(res.data, res.status);
          })
          .catch((error) => originalRequest.failure(error));

        break;

      default: // change error handling to display message to user
        store.dispatch(workflowActions.setError(error.response?.data));
        break;
    }

    return Promise.reject(error.response);
  };

  getUrlParams = (params) => {
    const urlParams = this.urlParams;
    const results = {};
    for (const param in params) {
      if (!urlParams[param]) {
        results[param] = params[param];
      }
    }
    return results;
  };

  get(options, success, failure) {
    return this._request(options, 'GET', success, failure);
  }

  patch(options, success, failure) {
    return this._request(options, 'PATCH', success, failure);
  }

  post(options, success, failure) {
    return this._request(options, 'POST', success, failure);
  }

  delete(options, success, failure) {
    return this._request(options, 'DELETE', success, failure);
  }

  _get_signal(options) {
    try {
      return options.signal.token;
    } catch (e) {
      return null;
    }
  }

  _request(options, method, success, failure) {
    return this.service
      .request({
        withCredentials: options.withCredentials || false,
        method: method,
        url: this.getPath(options.params),
        params: {
          enabled: true,
          ...options.params,
        },
        headers: {
          ...axios.defaults.headers,
          ...options.headers,
        },
        cancelToken: this._get_signal(options),
        responseType: 'json',
        data: options.data,
        success: success,
        failure: failure,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data, response.status);
          }
        },
        (e) => {
          if (failure) {
            failure(e);
          }
        },
      );
  }
}

export default Service;
