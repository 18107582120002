import { createBrowserRouter } from 'react-router-dom';
import LoginView from 'containers/views/LoginView/LoginView';
import InitWorkflow from 'containers/InitWorkflow';
import ForgotPassword from 'containers/views/ForgotPassword/ForgotPassword';
import ResetPassword from 'containers/views/ResetPassword/ResetPassword';
import ProviderInstructionView from 'containers/views/ProviderInstruction/ProviderInstructionView';
import RegisterProvider from 'containers/views/RegisterProvider/RegisterProvider';
import SuccessfulRegistration from 'containers/views/RegisterProvider/SuccessfulRegistration/SuccessfulRegistration';
import IDP from 'containers/views/IDP/IDP';
import EPCSEnrollment from 'containers/views/EPCSEnrollment/EPCSEnrollment';
import Prescription from 'Prescription';
import ChooseLocationAndMedication from 'forms/ChooseMed/ChooseLocationAndMedication';
import InitConfirmation from 'containers/initConfirmation';
import LAC from 'containers/views/LAC/LAC';
import GenericError from 'containers/views/GenericError/GenericError';
import EPCSInviteFailed from 'containers/views/EPCSInviteFailed/EPCSInviteFailed';
import InitConfirmationWorkflow from 'containers/InitConfirmationWorkflow';

import NotFoundPage from 'containers/views/NotFoundPage/NotFoundPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <LoginView />,
    children: [
      {
        path: 'workflow',
        element: <InitWorkflow />,
        children: [
          {
            path: 'choose-location-and-medication',
            element: <ChooseLocationAndMedication />,
          },
          {
            path: 'prescription',
            element: <Prescription />,
          },
          {
            path: 'identity-proofing',
            element: <IDP />,
          },
          {
            path: 'epcs-registration',
            element: <EPCSEnrollment />,
          },
          {
            path: 'initial-onboarding',
            element: <EPCSInviteFailed />,
          },
          {
            path: 'logical-access-control',
            element: <LAC />,
          },
          {
            path: 'error',
            element: <GenericError />,
          },
        ],
      },
    ],
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/reset_password/confirm',
    element: <ResetPassword />,
  },
  {
    path: 'register-information',
    element: <ProviderInstructionView />,
  },
  {
    path: 'lac_authorization',
    element: <InitConfirmationWorkflow />,
    children: [
      {
        path: 'confirm',
        element: <InitConfirmation />,
      },
    ],
  },
  {
    path: 'tou',
    element: <InitConfirmationWorkflow />,
    children: [
      {
        path: 'confirm',
        element: <InitConfirmation />,
      },
    ],
  },
  {
    path: 'register',
    element: <RegisterProvider />,
    children: [
      {
        path: 'onboarding-initiated',
        element: <SuccessfulRegistration />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);
