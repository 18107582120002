import DosingRow from 'components/DosingRow/DosingRow';

const NarcoTitratedDosing = () => {
  return (
    <div
      className="tab-pane"
      id="pills-titrated-1"
      role="tabpanel"
      aria-labelledby="pills-titrated-tab-1"
      tabIndex={0}
    >
      <h5 className="pt-3 text-info text-center">Titrated XYWAV Dosing: Titrate to Effect</h5>
      <h6 className="fw-bold">2 times a night dosing (For Narcolepsy and IH)</h6>
      <div className="col-3 offset-9 fw-bold small text-muted">
        Number of Days (at each titration step)
      </div>
      <DosingRow
        title="Starting Dose"
        withOperators
        fields={{
          doses: [
            'rems:titratedDosing:effect:nih:startingDose:dose:1',
            'rems:titratedDosing:effect:nih:startingDose:dose:2',
          ],
          totalDose: 'rems:titratedDosing:effect:nih:startingDose:dose:total',
          timeOfDrugTaking: 'rems:titratedDosing:effect:nih:startingDose:dose:numOfDays',
        }}
      />
      <DosingRow
        title="1st Titration"
        withOperators
        fields={{
          doses: [
            'rems:titratedDosing:effect:nih:1stTitration:dose:1',
            'rems:titratedDosing:effect:nih:1stTitration:dose:2',
          ],
          totalDose: 'rems:titratedDosing:effect:nih:1stTitration:dose:total',
          timeOfDrugTaking: 'rems:titratedDosing:effect:nih:1stTitration:dose:numOfDays',
        }}
      />
      <DosingRow
        title="2nd Titration"
        withOperators
        fields={{
          doses: [
            'rems:titratedDosing:effect:nih:2ndTitration:dose:1',
            'rems:titratedDosing:effect:nih:2ndTitration:dose:2',
          ],
          totalDose: 'rems:titratedDosing:effect:nih:2ndTitration:dose:total',
          timeOfDrugTaking: 'rems:titratedDosing:effect:nih:2ndTitration:dose:numOfDays',
        }}
      />
      <DosingRow
        title="3rd Titration"
        withOperators
        fields={{
          doses: [
            'rems:titratedDosing:effect:nih:3rdTitration:dose:1',
            'rems:titratedDosing:effect:nih:3rdTitration:dose:2',
          ],
          totalDose: 'rems:titratedDosing:effect:nih:3rdTitration:dose:total',
          timeOfDrugTaking: 'rems:titratedDosing:effect:nih:3rdTitration:dose:numOfDays',
        }}
      />

      <div className="col-12 small px-2 pt-3 fw-bold">
        <div>
          First dose is ordinarily taken at bedtime second dose is taken 2.5 to 4 hours later.
        </div>
        <div>
          *For pediatric patients who sleep more than 8 hours per night, the first dose of XYWAV may
          be given at bedtime or after an initial period of sleep.
        </div>
        <div>
          **If XYWAV is used in patients 7 years of age and older who weigh less than 20 kg, a lower
          starting dosage, lower maximum weekly dosage increases, and lower total maximum nightly
          dosage should be considered.
        </div>
      </div>
    </div>
  );
};

export default NarcoTitratedDosing;
