import { useState, useEffect } from 'react';
import { useFormContext, UseFormSetValue, UseFormGetValues } from 'react-hook-form';
import { reduxPropsDataDefault } from './Data';
import TextField from 'components/TextField/TextField';
import { useAppSelector } from 'hooks/useRedux';
import SelectField from 'components/selectField/SelectField';
import TextareaField from 'components/TextareaField/TextareaField';
import RadioButton from 'components/RadioButton/RadioButton';
import SignatureField from 'components/SignatureField/SignatureField';
import DateField from 'components/DateField/DateField';
import Checkbox from 'components/Checkbox/Checkbox';
import { ValuesType } from 'state/forms/forms.reducer';
import { workflowSelectors } from 'state/workflow';
import InteractiveItemList from 'components/InteractiveItemList/InteractiveItemList';

export type formTypes =
  | 'text'
  | 'select'
  | 'textarea'
  | 'radio'
  | 'signature'
  | 'date'
  | 'checkbox'
  | 'interMenu';
export interface FormFieldProps {
  identifier: string;
  type: formTypes;
  className?: string;
  style?: React.CSSProperties;
  readOnly?: boolean;
  units?: string;
  variant?: 'number' | 'text' | 'phone' | 'password' | 'numberAsText';
  radioVariant?: 'horizontal' | 'vertical';
  setValue?: (value: string) => void;
  setFormValue?: UseFormSetValue<ValuesType>;
  wholeNumber?: boolean;
  validation?: object;
  index?: number;
  step?: string | number;
  hideReq?: boolean;
  getValues?: UseFormGetValues<ValuesType>;
  setArrValue?: UseFormSetValue<ValuesType>;
  identifierForOther?: string;
  showIndicationInput?: boolean;
  registerForm?: boolean;
  isDisabled?: boolean;
  required?: boolean;
}

const FormField = ({
  identifier,
  identifierForOther,
  style,
  type,
  readOnly,
  units,
  variant,
  step,
  setValue,
  radioVariant,
  wholeNumber,
  validation,
  index,
  hideReq,
  getValues,
  setArrValue,
  showIndicationInput,
  registerForm,
  required,
  isDisabled = false,
}: FormFieldProps) => {
  const workflowForm = useAppSelector(workflowSelectors.workflowForm);
  const [field, setField] = useState(reduxPropsDataDefault);

  const {
    watch,
    control,
    formState: { errors },
    register,
    trigger,
    setValue: setFormValue,
  } = useFormContext();

  useEffect(() => {
    if (workflowForm) {
      const workflowField: {
        title: { eng: string };
        identifier: string;
        value: string;
        required: boolean;
        choices: { eng: string[] };
      } = workflowForm.getFieldByKey(identifier);
      if (workflowField) {
        setField(workflowField);
      }

      if (workflowField) {
        setField(workflowField);
      }
    }
  }, [workflowForm]);

  const setIdentifier = index ? `${index}:${identifier}` : identifier;
  const ComponentProps = {
    className: 'form-control',
    field: field,
    setField: setField,
  };

  const Components = {
    text: (
      <TextField
        {...ComponentProps}
        validation={validation}
        step={step}
        setValue={setValue}
        setFormValue={setFormValue}
        errors={errors}
        formKey={setIdentifier}
        wholeNumber={wholeNumber}
        register={register}
        readOnly={readOnly}
        units={units}
        hideReq={!field.required && hideReq}
        trigger={trigger}
        variant={variant || 'text'}
        title={typeof field.title === 'object' ? field.title.eng : field.title}
        required={field.required || required}
        isDisabled={isDisabled}
      />
    ),

    select: (
      <SelectField
        {...ComponentProps}
        setValue={setValue}
        watch={watch}
        trigger={trigger}
        choices={field.choices}
        formKey={setIdentifier}
        errors={errors}
        register={register}
        label={typeof field.title === 'object' ? field.title.eng : field.title}
        isRequired={field.required}
        hideReq={hideReq}
        isDisabled={isDisabled}
      />
    ),

    textarea: (
      <TextareaField
        style={style}
        {...ComponentProps}
        formKey={setIdentifier}
        register={register}
        title={typeof field.title === 'object' ? field.title.eng : field.title}
        isDisabled={isDisabled}
      />
    ),

    radio: (
      <RadioButton
        {...ComponentProps}
        formKey={setIdentifier}
        errors={errors}
        register={register}
        required={field.required}
        identifierForOther={identifierForOther}
        showIndicationInput={showIndicationInput}
        setValue={setFormValue}
        registerForm={registerForm}
        isDisabled={isDisabled}
        form={{
          id: 1,
          title: () => {
            if (radioVariant === 'horizontal') {
              return (
                <span className="me-2 small">
                  {field.required && <span className="text-danger">*</span>}
                  {typeof field.title === 'object' ? field.title.eng : field.title}
                </span>
              );
            } else {
              return (
                <div>
                  <label>
                    {typeof field.title === 'object' ? field.title.eng : field.title}
                    {field.required && <span className="text-danger">*</span>}
                  </label>
                </div>
              );
            }
          },
          options: field.choices ? field.choices.eng : [],
        }}
      />
    ),

    interMenu: (
      <InteractiveItemList
        label={typeof field.title === 'object' ? field.title.eng : field.title}
        formKey={setIdentifier}
        setValue={setArrValue}
        control={control}
        errors={errors}
        required={field.required}
        register={register}
        getValues={getValues}
        isDisabled={isDisabled}
      />
    ),

    signature: (
      <SignatureField
        label={typeof field.title === 'object' ? field.title.eng : field.title}
        register={register}
        formKey={setIdentifier}
        setValue={setFormValue}
        control={control}
        errors={errors}
        required={field.required}
      />
    ),

    date: (
      <DateField
        {...ComponentProps}
        validation={validation}
        required={field.required}
        title={typeof field.title === 'object' ? field.title.eng : field.title}
        errors={errors}
        formKey={setIdentifier}
        register={register}
        isDisabled={isDisabled}
      />
    ),

    checkbox: (
      <Checkbox
        required={field.required}
        formKey={setIdentifier}
        register={register}
        setValue={setFormValue}
        title={typeof field.title === 'object' ? field.title.eng : field.title}
        isDisabled={isDisabled}
      />
    ),
  };

  const isTypeValid = (type: string) => {
    return Object.keys(Components).find((name) => name === type);
  };

  return <>{isTypeValid(type) && Components[type]}</>;
};

export default FormField;
