import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch } from 'state/store';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { serviceTypeSelectors } from 'state/serviceType';
import { formsSelectors, formsActions } from 'state/forms';
import { useAppSelector } from 'hooks/useRedux';
import moveStep from 'hooks/moveStep';
import WorkflowNavigationButton from 'components/Buttons/WorkflowNavigationButton/WorkflowNavigationButton';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import DoseTabs from 'components/DoseTabs/DoseTabs';
import FormField from 'containers/FormField/FormField';
import ChangeTabModal from './ChangeTabModal/ChangeTabModal';
import TabForm from './TabForm/TabForm';
import XyremInfo from './xyrem/XyremInfo';
import XywavInfo from './xywav/XywavInfo';
import { ValuesType } from 'state/forms/forms.reducer';
import { removeTrailingZeros } from 'helpers/removeTrailingZeros';
import { checkIfStringContainsNumber } from 'helpers/checkIfStringContainsNumber';

const xywavTabs = [
  {
    id: 1,
    title: (
      <span>
        Titrated XYWAV Dosing
        <br />
        (2 times a night)
      </span>
    ),
  },
  {
    id: 2,
    title: (
      <span>
        Titrated XYWAV Dosing
        <br />
        (1 time a night)
      </span>
    ),
  },
  {
    id: 3,
    title: (
      <span>
        Fixed XYWAV Dosing
        <br />
        (2 times a night)
      </span>
    ),
  },
  {
    id: 4,
    title: (
      <span>
        Fixed XYWAV Dosing
        <br />
        (1 time a night)
      </span>
    ),
  },
];

const xyremTabs = [
  { id: 1, title: <span>Titrated XYREM Dosing: Titrate to Effect</span> },
  { id: 2, title: <span>Fixed XYREM Dosing</span> },
];

const DosingInfo = () => {
  const formId = useAppSelector((store) => store.workflow.workflow?.form.uuid);
  const formValues = useAppSelector(formsSelectors.formSelector);

  const dispatch = useAppDispatch();

  const serviceType = useAppSelector(serviceTypeSelectors.serviceType);
  const tabValueSelector = useAppSelector(formsSelectors.tabValue);

  const [tabValue, setTabValue] = useState(tabValueSelector);
  const [modalData, setModalData] = useState({ show: false, value: 1 });
  const { onNextClick, onPrevClick } = moveStep();

  const methods = useForm<typeof formValues>({
    defaultValues: formValues,
  });

  type FormObject = {
    [key: string]: FormObject | string | string[];
  };
  const checkEmpty = (obj: FormObject) => {
    for (const key in obj) {
      if (key.includes('rems')) {
        if (obj[key] instanceof Object === true) {
          if (checkEmpty(obj[key] as FormObject) === false) return false;
        } else {
          if (obj[key] && obj[key].length !== 0) return false;
        }
      }
    }
    return true;
  };

  useEffect(() => {
    const setFormToDirty = !checkEmpty(formValues);

    // hack to set form dirty when provider came back to this step.
    if (setFormToDirty) {
      methods.setValue('rems:dose:instructions', `${formValues['rems:dose:instructions']} `, {
        shouldDirty: true,
      });
    }
  }, []);

  const clear = () => {
    const clearedTabsData: ValuesType = {};

    Object.keys(formValues).map((key) => {
      if (key.includes('rems')) {
        clearedTabsData[key] = '';
      } else {
        clearedTabsData[key] = formValues[key];
      }
    });

    methods.reset(clearedTabsData);
    dispatch(formsActions.setFormValues({ formId, values: clearedTabsData }));
  };

  const handleTabChange = (value: number) => {
    if (value === tabValue) {
      return;
    }

    if (!checkEmpty(formValues) || !checkEmpty(methods.getValues())) {
      return setModalData({ show: true, value });
    }
    setTabValue(value);
  };

  const onSubmit = (data: ValuesType, e: any) => {
    const submitter = e?.nativeEvent.submitter;
    const buttonName = submitter[Object.keys(submitter)[1]].name;
    if (buttonName === 'warning') {
      onPrevClick(formId, data);
      dispatch(formsActions.setTabValue(tabValue));
    } else {
      const interpolatedData = { ...data };
      Object.getOwnPropertyNames(data).forEach((key) => {
        if (checkIfStringContainsNumber(data[key]) && key.includes('rems')) {
          interpolatedData[key] = removeTrailingZeros(data[key]);
        }
      });
      onNextClick(formId, interpolatedData);
      dispatch(formsActions.setTabValue(tabValue));
    }
  };

  const onError = (_: any, e: any) => {
    const submitter = e?.nativeEvent.submitter;
    const buttonName = submitter[Object.keys(submitter)[1]].name;
    const data = methods.getValues();

    if (buttonName === 'warning') {
      onPrevClick(formId, data);
      dispatch(formsActions.setTabValue(tabValue));
    }
  };

  const setTabs = serviceType === 'xywav' ? xywavTabs : xyremTabs;
  const setDescription = serviceType === 'xywav' ? <XywavInfo /> : <XyremInfo />;

  return (
    <div>
      <FormWrapper
        text={`${(serviceType as string).toUpperCase()} Dosing Information`}
        type={serviceType}
      >
        <FormProvider {...methods}>
          <ChangeTabModal
            modalData={modalData}
            setModalData={setModalData}
            setTabValue={(value) => {
              const newTabValue = setTabValue(value);
              clear();
              return newTabValue;
            }}
          />
          <form
            noValidate
            onSubmit={methods.handleSubmit(onSubmit, onError)}
          >
            <div className="card-body p-0">
              {setDescription}
              <div className="row px-3 py-1">
                <div className="col">
                  <DoseTabs
                    serviceType={serviceType}
                    tabs={setTabs}
                    tabValue={tabValue}
                    handleTabChange={handleTabChange}
                  />
                  <TabForm
                    serviceType={serviceType}
                    tabValue={tabValue}
                  />

                  <div
                    className="tab-content"
                    id="pills-content"
                  ></div>
                  <div className="row py-2">
                    <div className="col-12">
                      <FormField
                        type="textarea"
                        identifier="rems:dose:instructions"
                        style={{ height: '150px', fontSize: '0.85rem' }}
                      />
                    </div>
                  </div>
                  <div className="p-2">
                    <hr className="text-muted" />
                  </div>
                  <div className="row px-2 py-1">
                    <div className="col-4">
                      <FormField
                        type="radio"
                        identifier="rems:prescription:quantity"
                      />
                    </div>
                    <div className="col-4">
                      <FormField
                        type="radio"
                        identifier="rems:prescription:refills"
                      />
                    </div>
                  </div>
                </div>
                <div className="row px-2 py-1">
                  <div className="col-12 text-center">
                    <WorkflowNavigationButton
                      text="Previous"
                      type="warning"
                      icon={faChevronLeft}
                      iconLeft
                    />
                    <WorkflowNavigationButton
                      text="Next Step"
                      type="primary"
                      icon={faChevronRight}
                      iconRight
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </FormWrapper>
    </div>
  );
};

export default DosingInfo;
