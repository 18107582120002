import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import { formsSelectors, formsActions } from 'state/forms';
import moveStep from 'hooks/moveStep';
import FormField from 'containers/FormField/FormField';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import WorkflowNavigationButton from 'components/Buttons/WorkflowNavigationButton/WorkflowNavigationButton';
import { dataExample } from 'constants/formsDevData';
import {
  INVALID_DATE,
  VALUE_TOO_LONG,
  VALUE_TOO_SHORT,
  INVALID_EMAIL,
  VALUE_NAME,
  VALUE_ADDRESS,
  VALUE_CITY,
  FIELD_MISSING,
  INVALID_PHONE,
} from 'constants/validationMessages';
import { emailRegex } from 'constants/validationRules';
import DefaultModal from 'components/Modal/Modal';

const PatientInfo = () => {
  const [showModal, setShowModal] = useState(false);
  const notSavedItemList = useAppSelector((store) => store.forms.notSavedItemList);
  const dispatch = useAppDispatch();

  const workflow = useAppSelector((store) => store.workflow.workflow);
  const formValues = useAppSelector(formsSelectors.formSelector);

  const defaultValues = process.env.REACT_APP_ENVIRONMENT === 'dev' ? formValues : dataExample;
  const formId = workflow?.form.uuid;

  useEffect(() => {
    if (!formValues && workflow) {
      dispatch(formsActions.loadForms({ workflow, formId }));
    }
  }, []);

  const methods = useForm<typeof formValues>({
    defaultValues,
  });

  const dobValue = methods.watch('patient:dob');

  const showIndicationInput = methods.watch('patient:indicationOfUse') === 'Other';

  const { onNextClick } = moveStep();

  const onSubmit = (data: typeof formValues) => {
    if (notSavedItemList.length > 0) {
      setShowModal(true);
      return;
    }
    if (!showIndicationInput) {
      data['patient:indicationOfUse:other'] = '';
    }

    onNextClick(formId, data);
  };

  return (
    <FormWrapper
      text="Patient Information"
      type="patient"
    >
      <DefaultModal
        text={`You have unsaved ${notSavedItemList
          .map((str) => {
            return ' ' + str.replace('patient:', '');
          })
          .join(' and')} data.  Any data entered will be discarded.  Are you sure?`}
        cancelButtonText="Cancel"
        continueButtonText="Yes, Continue"
        show={showModal}
        handleSubmit={() => {
          setShowModal(false);
          dispatch(formsActions.setNotSavedItemList([]));
          onNextClick(formId, methods.getValues());
        }}
        handleClose={() => {
          setShowModal(false);
        }}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="p-0">
            <div className="bg-light w-auto p-3">
              <div className="row">
                <div className="col-auto">
                  <FormField
                    type="radio"
                    identifier="patient:indicationOfUse"
                    radioVariant="horizontal"
                    identifierForOther="patient:indicationOfUse:other"
                    showIndicationInput={showIndicationInput}
                  />
                </div>
              </div>
            </div>
            <div className="row px-3 py-1">
              <div className="col-sm-6 col-md-3">
                <FormField
                  type="text"
                  identifier="patient:firstName"
                  validation={{
                    pattern: {
                      value: /^[a-z A-Z&)/('.,-]*$/,
                      message: VALUE_NAME,
                    },
                    maxLength: {
                      value: 35,
                      message: VALUE_TOO_LONG,
                    },
                  }}
                />
              </div>
              <div className="col-sm-6 col-md-3">
                <FormField
                  type="text"
                  identifier="patient:middle:initial"
                  validation={{
                    pattern: {
                      value: /^[a-z A-Z&)/('.,-]*$/,
                      message: VALUE_NAME,
                    },
                    maxLength: {
                      value: 35,
                      message: VALUE_TOO_LONG,
                    },
                  }}
                />
              </div>
              <div className="col-sm-6 col-md-3">
                <FormField
                  type="text"
                  identifier="patient:lastName"
                  validation={{
                    pattern: {
                      value: /^[a-z A-Z&)/('.,-]*$/,
                      message: VALUE_NAME,
                    },
                    maxLength: {
                      value: 35,
                      message: VALUE_TOO_LONG,
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row px-3 py-1">
            <div className="col-sm-6 col-md-3">
              <FormField
                type="radio"
                identifier="patient:gender"
              />
            </div>
            <FormField
              type="date"
              identifier="patient:dob"
              validation={{
                validate: (value: string | string[]) => {
                  if (typeof value === 'string') {
                    const Age = dayjs(dobValue as unknown as string, 'MM/DD/YYYY').diff(
                      dayjs(),
                      'year',
                    );
                    const isValid =
                      dayjs(value, 'MM/DD/YYYY', true).isValid() &&
                      dayjs(value, 'MM/DD/YYYY').isBefore(dayjs());
                    if (!isValid || Age <= -150) {
                      return INVALID_DATE;
                    }
                    return true;
                  }
                },
              }}
            />
            <div className="col-sm-6 col-md-3">
              <FormField
                type="text"
                variant="number"
                units="kg"
                identifier="patient:weight"
                validation={{
                  validate: (value: string) => {
                    // Check if dob is in the future
                    if (dayjs(dobValue, 'MM/DD/YYYY').isAfter(new Date())) {
                      return true;
                    }

                    // get difference in years between dob and today
                    const Age = dayjs(dobValue as unknown as string, 'MM/DD/YYYY').diff(
                      dayjs(),
                      'year',
                    );

                    const setWeightRequired = Age >= -18 && Age <= 0;
                    if (setWeightRequired && !value && !dobValue.includes('/0000')) {
                      return 'Weight is required';
                    }
                    return true;
                  },
                  pattern: {
                    value: /^[0-9.,]+$/g,
                    message: VALUE_ADDRESS,
                  },
                }}
              />
            </div>
            <div className="col-sm-6 col-md-3 small">
              <br />
              &dagger; denotes required field for pediatric patients on initial fill and restarts
            </div>
            <div className="p-2">
              <hr className="text-muted" />
            </div>
            <div className="row px-3 py-1">
              <div className="col-sm-6 col-md-3">
                <FormField
                  type="text"
                  identifier="patient:address1"
                  validation={{
                    pattern: {
                      value: /^[a-z A-Z0-9&#.,;:']+$/g,
                      message: VALUE_ADDRESS,
                    },
                    maxLength: {
                      value: 100,
                      message: VALUE_TOO_LONG,
                    },
                  }}
                />
              </div>
              <div className="col-sm-6 col-md-3">
                <FormField
                  type="text"
                  identifier="patient:address2"
                  validation={{
                    pattern: {
                      value: /^[a-z A-Z0-9&#.,;:']+$/g,
                      message: VALUE_ADDRESS,
                    },
                    maxLength: {
                      value: 100,
                      message: VALUE_TOO_LONG,
                    },
                  }}
                />
              </div>
              <div className="col-sm-4 col-md-2">
                <FormField
                  type="text"
                  identifier="patient:city"
                  validation={{
                    pattern: {
                      value: /^[a-z A-Z,-.;'&/()]*$/,
                      message: VALUE_CITY,
                    },
                    maxLength: {
                      value: 100,
                      message: VALUE_TOO_LONG,
                    },
                  }}
                />
              </div>
              <div className="col-sm-4 col-md-2">
                <FormField
                  type="select"
                  identifier="patient:state"
                />
              </div>
              <div className="col-sm-4 col-md-2">
                <FormField
                  type="text"
                  identifier="patient:zipcode"
                  validation={{
                    pattern: {
                      value:
                        /^((\d{5}-\d{4})|(\d{5})|(\d{9})|([a-zA-Z]\d[a-zA-Z]\s\d[a-zA-Z]\d)|([a-zA-Z]\d[a-zA-Z]\d[a-zA-Z]\d))$/,
                      message: FIELD_MISSING,
                    },
                    minLength: {
                      value: 5,
                      message: VALUE_TOO_SHORT,
                    },
                    maxLength: {
                      value: 10,
                      message: VALUE_TOO_LONG,
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="p-2">
            <hr className="text-muted" />
          </div>
          <div className="row px-3 py-1">
            <div className="col-sm-6 col-md-3">
              <FormField
                type="text"
                identifier="patient:phone:primary"
                variant="phone"
                validation={{
                  validate: (value: string) => {
                    return !value.includes('_') || !/\d/.test(value) || INVALID_PHONE;
                  },
                }}
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <FormField
                type="text"
                identifier="patient:phone:mobile"
                variant="phone"
                validation={{
                  validate: (value: string) => {
                    return !value.includes('_') || !/\d/.test(value) || INVALID_PHONE;
                  },
                }}
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <FormField
                type="text"
                identifier="patient:phone:work"
                variant="phone"
                validation={{
                  validate: (value: string) => {
                    return !value.includes('_') || !/\d/.test(value) || INVALID_PHONE;
                  },
                }}
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <FormField
                type="text"
                identifier="patient:email"
                validation={{
                  pattern: {
                    value: emailRegex,
                    message: INVALID_EMAIL,
                  },
                }}
              />
            </div>
          </div>
          <div className="p-2"></div>
          <div className="row g-0 border-top border-bottom">
            <div className="col-12 col-md-6">
              <div className="bg-light">
                <div className="fw-bold px-2">
                  Medications<span className="text-danger">*</span>
                </div>
                <div className="small px-2">
                  List all known current prescription and non-prescription medications and dosages.
                  <br />
                  If there are no medications to report, enter 'None'.
                </div>
              </div>
              <FormField
                identifier="patient:medications"
                setArrValue={methods.setValue}
                getValues={methods.getValues}
                type={'interMenu'}
              />
            </div>
            <div className="col-12 col-md-6">
              <div className="bg-light">
                <div className="fw-bold px-2">
                  Comorbidities<span className="text-danger">*</span>
                </div>
                <div className="small px-2">
                  List all known comorbidities.
                  <br />
                  If there are no comorbidities to report, enter 'None'.
                </div>
              </div>
              <FormField
                identifier="patient:comorbidities"
                setArrValue={methods.setValue}
                getValues={methods.getValues}
                type={'interMenu'}
              />
            </div>
            <div className="col-12 col-md-6"></div>
            <div className="col-12 col-md-6"></div>
          </div>
          <div className="row p-3 text-center">
            <WorkflowNavigationButton
              text="Next Step"
              type="primary"
              icon={faChevronRight}
              iconRight
            />
          </div>
        </form>
      </FormProvider>
    </FormWrapper>
  );
};

export default PatientInfo;
