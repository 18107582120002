import { Outlet, useLocation, useOutlet } from 'react-router-dom';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoBar from 'components/InfoBar/InfoBar';
import FormField from 'containers/FormField/FormField';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { workflowActions, workflowSelectors } from 'state/workflow';
import { formsSelectors } from 'state/forms';
import { FormProvider, useForm } from 'react-hook-form';
import moveStep from 'hooks/moveStep';
import { INVALID_EMAIL, VALUE_NAME, VALUE_TOO_LONG } from 'constants/validationMessages';
import { authSelectors } from 'state/auth';
import { emailRegex } from 'constants/validationRules';

const LAC = () => {
  const { workflow } = useAppSelector(workflowSelectors.workflowSelector);
  const formValues = useAppSelector(formsSelectors.formSelector);
  const outlet = useOutlet();

  const formId = workflow?.form?.uuid;
  const methods = useForm<typeof formValues>();
  const user = useAppSelector(authSelectors.userSelector);
  const dispatch = useAppDispatch();

  const { onNextClick } = moveStep();

  const onSubmit = (data: typeof formValues) => {
    dispatch(workflowActions.setLoading(true));
    onNextClick(formId, data, false);
  };

  const location = useLocation();
  const identifier = location.state?.identifier;

  if (!identifier) {
    return null;
  }

  if (outlet) {
    return <Outlet />;
  }

  return (
    <>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <div className="row p-3 text-center">
            <div className="col-12">
              {user && <h4 className="text-info">{`Hello Dr. ${user.name}`}</h4>}
              <h5 className="fw-bold">You are one step away from being fully enrolled</h5>
              <div className="fa-6x">
                <FontAwesomeIcon
                  icon={faUserGroup}
                  className="text-muted"
                />
              </div>
              <div className="col-12">
                <div>
                  {
                    'The DEA requires that an acting administrator, or equivalent person, grants you access to electronically prescribe controlled substances. This individual will verify that you are authorized to prescribe controlled substances under the DEA license number listed in the Logical Access Control (LAC) User Interface. This is typically the practice or hospital administrator.'
                  }
                </div>
              </div>
            </div>
            <div className="col-12 pt-3 text-start">
              <h5 className="py-3 text-info">{'Logical Access Control (LAC) User Details'}</h5>
            </div>
            <div className="col-12 text-start">
              <p className="fw-bold">
                You will require someone from your practice to serve as the second authorizing user.
                Please enter the details for the second user below. This user will receive an email
                containing instructions on how to complete the final step. Note that you will need
                to be present with the user to enter your TFA credentials.
              </p>
            </div>
            <div className="col-sm-6 col-md-3 text-start">
              <FormField
                type="text"
                identifier="authorizingUser:firstName"
                validation={{
                  pattern: {
                    value: /^[a-z A-Z&)/('.,-]*$/,
                    message: VALUE_NAME,
                  },
                  maxLength: {
                    value: 35,
                    message: VALUE_TOO_LONG,
                  },
                }}
              />
            </div>
            <div className="col-sm-6 col-md-3 text-start">
              <FormField
                type="text"
                identifier="authorizingUser:lastName"
                validation={{
                  pattern: {
                    value: /^[a-z A-Z&)/('.,-]*$/,
                    message: VALUE_NAME,
                  },
                  maxLength: {
                    value: 35,
                    message: VALUE_TOO_LONG,
                  },
                }}
              />
            </div>
            <div className="col-sm-6 col-md-3 text-start">
              <FormField
                type="text"
                identifier="authorizingUser:email"
                validation={{
                  pattern: {
                    value: emailRegex,
                    message: INVALID_EMAIL,
                  },
                }}
              />
            </div>
            <div className="col-sm-6 col-md-3 text-start pt-2">
              <button
                className="btn btn-primary m-3"
                type="submit"
                onClick={methods.handleSubmit(onSubmit)}
              >
                <FontAwesomeIcon icon={faEnvelope} /> Send LAC Email
              </button>
            </div>
          </div>
          <InfoBar>
            If you have questions or need additional assistance,{' '}
            <a
              href="https://drfirsthelp1649968111jazz.zendesk.com"
              target="_blank"
            >
              chat with us
            </a>{' '}
            or contact support at (855) 863-1355. Please have your NPI and email address ready.
          </InfoBar>
        </FormProvider>
      </form>
    </>
  );
};

export default LAC;
