import { useRef, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldErrorsImpl,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import { formsSelectors, formsActions } from 'state/forms';
import SignatureCanvas from 'react-signature-canvas';
import ErrorBadge from '../ErrorBadge/ErrorBadge';
import type { ValuesType } from 'state/forms/forms.reducer';

interface SignatureFieldProps {
  label: string;
  setValue?: UseFormSetValue<ValuesType>;
  register: UseFormRegister<ValuesType>;
  required?: boolean;
  control?: Control<ValuesType>;
  formKey: string;
  errors?: Partial<
    FieldErrorsImpl<{
      [x: string]: NonNullable<string | string[]>;
    }>
  >;
}
const SignatureField = ({
  label,
  setValue,
  control,
  formKey,
  errors,
  required,
}: SignatureFieldProps) => {
  const dispatch = useAppDispatch();

  const redBorderError =
    errors && errors[formKey] && 'border border-danger border-opacity-75 border-2';

  const validationBudge = errors && errors[formKey] && (
    <ErrorBadge
      errors={errors}
      formKey={formKey}
    />
  );

  const sigCanvas = useRef<SignatureCanvas>(null);

  const fullSignature = useAppSelector(formsSelectors.fullSignature);

  useEffect(() => {
    if (fullSignature) {
      sigCanvas.current?.fromDataURL(fullSignature);
    }
  }, []);

  const handleChange = (field: ControllerRenderProps<ValuesType, string>) => {
    dispatch(formsActions.setFullSignature(sigCanvas.current?.toDataURL()));
    return field.onChange(sigCanvas.current?.getTrimmedCanvas().toDataURL());
  };

  const handleClear = () => {
    sigCanvas.current?.clear();
    setValue?.(formKey, '');
    dispatch(formsActions.setFullSignature(''));
  };

  return (
    <>
      <div className="d-flex align-items-end justify-content-between">
        <label className="form-label">
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={handleClear}
        >
          Clear
        </Button>
      </div>

      <div
        className="signature-field"
        data-testid="signature-field"
      >
        {validationBudge}
        <Controller
          name={formKey}
          control={control}
          render={({ field }) => (
            <SignatureCanvas
              ref={sigCanvas}
              canvasProps={{ className: `sigCanvas ${redBorderError}` }}
              onEnd={() => {
                handleChange(field);
              }}
            />
          )}
          rules={{
            required: {
              value: true,
              message: 'Signature is required',
            },
          }}
        />
      </div>
    </>
  );
};

export default SignatureField;
