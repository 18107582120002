import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { workflowActions, workflowSelectors } from 'state/workflow';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { authSelectors } from 'state/auth';
import { useEffect } from 'react';
import { progressBarActions } from 'state/progressBar';

interface ErrorComponentProps {
  disableReturn?: boolean;
  customError?: any;
  subTitle?: string;
  disableButton?: boolean;
  errorSubTitle?: string;
}
const SUB_TITLE = 'Please return to the previous step and try to re-run';
const DEFAULT_ERROR_HEADER = 'Something went wrong';
const DEFAULT_ERROR_TITLE = 'Please try again. If the problem persists contact support';
const DEFAULT_ERROR_BUTTON_TEXT = 'Retry';

const ErrorComponent = ({
  disableReturn,
  customError,
  subTitle = SUB_TITLE,
  disableButton = false,
  errorSubTitle,
}: ErrorComponentProps) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(authSelectors.userSelector);
  const error = useAppSelector(workflowSelectors.errorSelector);
  const lastStepActionName = useAppSelector(workflowSelectors.lastStepActionNameSelector);

  const errorCode = !Array.isArray(error) && typeof error === 'object' && error?.code;

  useEffect(() => {
    dispatch(workflowActions.setLastStepBeforeErrorClose(lastStepActionName));
  }, []);

  const closeError = () => {
    dispatch(workflowActions.setError(false));
    if (lastStepActionName === 'submit_forms') {
      dispatch(progressBarActions.prevStep());
      dispatch(workflowActions.workflowPreviousStep());
      return;
    }

    dispatch(workflowActions.workflowRetryLatestActionStep());
  };

  const getErrorHeader = (): string => {
    if (customError) {
      return customError;
    }

    if (errorCode && !error.title && error.message) {
      return error.message;
    }

    if (errorCode && error.title) {
      if (errorCode === '0002' && lastStepActionName === 'unsigned_prescription') {
        return 'Something went wrong with cancelling the transaction';
      }
      return error.title;
    }
    return DEFAULT_ERROR_HEADER;
  };

  const getErrorTitle = (): string => {
    if (errorCode && error.sub_title) {
      if (errorCode === '0002' && lastStepActionName === 'unsigned_prescription') {
        return DEFAULT_ERROR_TITLE;
      }

      return error.sub_title;
    }
    if (errorSubTitle) {
      return errorSubTitle;
    }

    return subTitle;
  };

  const getErrorButtonText = (): string => {
    if (errorCode && error.button_text) {
      if (errorCode === '0002' && lastStepActionName === 'unsigned_prescription') {
        return DEFAULT_ERROR_BUTTON_TEXT;
      }

      return error.button_text;
    }

    return DEFAULT_ERROR_BUTTON_TEXT;
  };

  return (
    <>
      <div className="card-body p-0">
        <div className="row px-3 py-1">
          <div className="row g-0 text-center">
            <div className="col-12 p-3">
              {user && <h4 className="text-info">{`Hello Dr. ${user.name}`}</h4>}

              <h5 className="fw-bold">{getErrorHeader()}</h5>
              <div className="fa-6x">
                <i className="fa-solid fa-triangle-exclamation text-danger"></i>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="text-danger"
                />
              </div>
            </div>
            {!disableReturn && (
              <div className="col-12 bg-light py-3">
                <h5 className="fw-bold py-3">{getErrorTitle()}</h5>
                <div>
                  <div>
                    <div className="col-12 bg-light py-3">
                      <div>
                        <a
                          href="https://drfirsthelp1649968111jazz.zendesk.com"
                          target="_blank"
                        >
                          Chat with us
                        </a>{' '}
                        or contact support at (855) 863-1355 and provide the following information
                      </div>
                      {user && (
                        <>
                          <div className="mt-2 fw-bold">ERROR INFORMATION</div>
                          <div> {errorCode && error.message}</div>
                          <div>
                            Practice Username: {user.rc_practice_username} | Username:{' '}
                            {user.rc_username}
                          </div>
                          <div>NPI: {user.npi}</div>
                          <div>Email: {user.email}</div>
                        </>
                      )}
                    </div>
                  </div>
                  {!disableButton && (
                    <div className="d-grid gap-2 col-2 mx-auto">
                      <a
                        className="btn btn-primary m-2 error-button "
                        onClick={closeError}
                        role="button"
                      >
                        {getErrorButtonText()}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorComponent;
