import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type InitialState = {
  values: {
    [key: string]: string;
  };
};

const initialState: InitialState = {
  values: {},
};

const hyperlinkSlice = createSlice({
  name: 'hyperlink',

  initialState,
  reducers: {
    setHyperlinkValues(state, action: PayloadAction<{ [key: string]: string }>) {
      state.values = action.payload;
    },
    resetHyperlinkValues(state) {
      state.values = {};
    },
  },
});

export const reducerHyperlinkActions = hyperlinkSlice.actions;
export default hyperlinkSlice.reducer;
