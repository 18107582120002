import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ReactNode } from 'react';

interface DoseTabsProps {
  serviceType?: string;
  tabs: {
    id: number;
    title: ReactNode;
  }[];
  tabValue: number;
  handleTabChange: (value: number) => void;
}

const DoseTabs = ({ tabs, handleTabChange, tabValue, serviceType }: DoseTabsProps) => {
  const renderTabs = tabs.map((tab) => {
    return (
      <Tab
        key={tab.id}
        eventKey={tab.id}
        title={tab.title}
      ></Tab>
    );
  });

  return (
    <Tabs
      defaultActiveKey={tabs[0].id}
      id="controlled-tab-example"
      activeKey={tabValue}
      onSelect={(value) => {
        handleTabChange(Number(value));
      }}
      className="mb-3"
      fill={serviceType === 'xywav'}
    >
      {renderTabs}
    </Tabs>
  );
};

export default DoseTabs;
