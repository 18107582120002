import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import WorkflowNavigationButton from '../../../components/Buttons/WorkflowNavigationButton/WorkflowNavigationButton';
import FormWrapper from '../../../components/FormWrapper/FormWrapper';
import FormField from '../../../containers/FormField/FormField';
import { useFormContext } from 'react-hook-form';

interface PrescriptionFormXywavProps {
  today: string;
}

const PrescriptionFormXywav = ({ today }: PrescriptionFormXywavProps) => {
  const { setValue, register } = useFormContext();

  useEffect(() => {
    setValue('prescription:signingDate', today);
  }, [today]);

  return (
    <FormWrapper
      type="prescription"
      text="Prescriber Verification"
    >
      <div className="card-body p-0">
        <div className="row px-3 py-1">
          <div
            className="col-12"
            data-testid="formInfo"
          >
            <p>
              <span className="fw-bold">Prescriber:</span> Signature verification is required on the
              XYWAV Prescription Form as acknowledgment that you have an understanding of and/or
              agree to the following:
            </p>
            <p className="fw-bold">
              I understand that XYWAV is indicated for the treatment of cataplexy or excessive
              daytime sleepiness (EDS) in patients 7 years of age and older with narcolepsy. I
              understand that XYWAV is indicated for the treatment of idiopathic hypersomnia in
              adults.
            </p>
            <p className="fw-bold">I understand that:</p>
            <ul>
              <li>
                XYWAV is a CNS depressant and can cause obtundation and clinically significant
                respiratory depression at recommended doses
              </li>
              <li>
                Alcohol and sedative hypnotics are contraindicated in patients who are using XYWAV
              </li>
              <li>
                Concurrent use of XYWAV with other CNS depressants, including but not limited to
                opioid analgesics, benzodiazepines, sedating antidepressants or antipsychotics,
                sedating anti-epileptics, general anesthetics, muscle relaxants, and/or illicit CNS
                depressants, may increase the risk of respiratory depression, hypotension, profound
                sedation, syncope, and death
              </li>
              <ul>
                <li>
                  If use of these CNS depressants in combination with XYWAV is required, dose
                  reduction or discontinuation of one or more CNS depressants (including XYWAV)
                  should be considered
                </li>
                <li>
                  If short-term use of an opioid (e.g., post- or perioperative) is required,
                  interruption of treatment with XYWAV should be considered
                </li>
              </ul>
              <li>
                Patients who have sleep apnea or compromised respiratory function (e.g., asthma,
                COPD, etc.) may be at higher risk of developing respiratory depression, loss of
                consciousness, coma, and death with XYWAV use
              </li>
              <li>
                XYWAV is a Schedule III controlled substance with potential for abuse and misuse
              </li>
              <li>
                Safe use and handling by patients is important in order to prevent abuse/misuse and
                accidental exposure to family/friends, including children
              </li>
              <li>
                XYWAV is to be prescribed only to patients enrolled in the XYWAV and XYREM REMS
              </li>
            </ul>
            <div className="fw-bold">
              I have read and understand the Prescribing Information and XYWAV and XYREM REMS
              Prescriber Brochure.
            </div>
            <div className="fw-bold">I have screened this patient for:</div>
            <ul>
              <li>History of alcohol or substance abuse</li>
              <li>History of sleep-related breathing disorders</li>
              <li>History of compromised respiratory function</li>
              <li>
                Concomitant use of sedative hypnotics, other CNS depressants, or other potentially
                interacting agents
              </li>
              <li>History of depression or suicidality</li>
            </ul>
            <div className="fw-bold">I have counseled this patient and/or caregiver on:</div>
            <ul>
              <li>The serious risks associated with XYWAV</li>
              <li>Contraindications (alcohol and sedative hypnotics)</li>
              <li>
                Risk of concomitant use of XYWAV with alcohol, other CNS depressants, or other
                potentially interacting agents
              </li>
              <li>Preparation and dosing instructions for XYWAV</li>
              <li>Risk of abuse and misuse associated with use of XYWAV</li>
              <li>
                Risk of operating hazardous machinery, including automobiles or airplanes, for the
                first 6 hours after taking a dose of XYWAV
              </li>
              <li>Preparation and dosing instructions for XYWAV</li>
              <li>Safe use, handling, and storage of XYWAV</li>
            </ul>
          </div>
          <div className="col-12 small my-4">
            <div>
              <span className="fw-bold">Prescriber Verification</span> -My signature below signifies
              that: I understand the statements and agree to the REMS requirements; XYWAV is
              medically appropriate for this patient; and I have informed the patient and/or
              caregiver that the XYWAV and XYREM REMS will send him or her the appropriate
              educational material (XYWAV Patient Quick Start Guide for adult patients and XYWAV
              Brochure for Pediatric Patients and their Caregivers for pediatric patients) with the
              first prescription fill.
            </div>
          </div>
          <div className="col-12 col-md-2 offset-md-7">
            <label>
              Signing Date<span className="text-danger">*</span>
            </label>
            <div className="input-group">
              <input
                type="text"
                data-testid="signingDate"
                className="form-control form-control-sm"
                {...register('prescription:signingDate')}
                id="date"
                name="date"
                value={today}
                disabled
              />
              <span className="input-group-text text-muted">
                <i className="fas fa-calendar-alt">
                  <FontAwesomeIcon icon={faCalendar} />
                </i>
              </span>
            </div>
          </div>
          <div className="col-12 col-md-6 offset-md-3 pt-3">
            <FormField
              identifier="prescription:providerSignature"
              type="signature"
              setFormValue={setValue}
            />
          </div>
        </div>
        <div className="row px-3 py-1">
          <div className="col-12 text-center">
            <WorkflowNavigationButton
              text="Previous"
              type="warning"
              icon={faChevronLeft}
              iconLeft
            />
            <WorkflowNavigationButton
              text="Complete Prescription"
              type="primary"
              icon={faChevronRight}
              iconRight
            />
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

export default PrescriptionFormXywav;
