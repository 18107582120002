import { useState, useEffect } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddNewItem from '../AddNewItem/AddNewItem';
import ItemWithDeleteOption from '../ItemWithDeleteOption/ItemWithDeleteOption';
import { ValuesType } from 'state/forms/forms.reducer';
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormRegister,
  UseFormSetValue,
  UseFormGetValues,
} from 'react-hook-form';
import ErrorBadge from './../ErrorBadge/ErrorBadge';
import { useAppSelector } from 'hooks/useRedux';
import { useAppDispatch } from 'state/store';
import { formsActions } from 'state/forms';
export interface InteractiveItemListProps {
  label: string;
  getValues?: UseFormGetValues<ValuesType>;
  setValue?: UseFormSetValue<ValuesType>;
  register: UseFormRegister<ValuesType>;
  required?: boolean;
  control?: Control<ValuesType>;
  formKey: string;
  isDisabled?: boolean;
  errors?: Partial<
    FieldErrorsImpl<{
      [x: string]: NonNullable<string | string[]>;
    }>
  >;
}

const InteractiveItemList = ({
  label,
  formKey,
  setValue,
  getValues,
  errors,
  required,
  control,
  isDisabled,
}: InteractiveItemListProps) => {
  const initialState = getValues && (getValues(formKey) as string[]);
  const notSaved = useAppSelector((store) => store.forms.notSavedItemList);
  const dispatch = useAppDispatch();

  const [items, setItems] = useState<string[]>(initialState || []);
  const [newItemValue, setNewItemValue] = useState<string>('');
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [errorsInput, setErrorsInput] = useState<string[]>([]);

  useEffect(() => {
    if (setValue) {
      setValue(formKey, items);
    }
  }, [items.length]);

  useEffect(() => {
    if (isPanelOpen) {
      dispatch(formsActions.setNotSavedItemList([...notSaved, formKey]));
    } else {
      const newNotSaved = notSaved.filter((item) => item !== formKey);
      dispatch(formsActions.setNotSavedItemList(newNotSaved));
    }
  }, [isPanelOpen]);

  const handleOnSave = () => {
    const checkNewValue = newItemValue.replace(/ /g, '');

    if (items.find((m) => m.replace(/ /g, '') === checkNewValue)) {
      setErrorsInput(['This item has already been added']);
      return;
    }

    if (checkNewValue === '') {
      setIsPanelOpen(false);
      setErrorsInput([]);
      setNewItemValue('');
      return;
    }

    setItems([...items, newItemValue]);
    setIsPanelOpen(false);
    setNewItemValue('');
    setErrorsInput([]);
  };

  const validationBudge = errors && errors[formKey] && (
    <ErrorBadge
      errors={errors}
      formKey={formKey}
    />
  );
  return (
    <div className="p-2">
      {items.map((item, index) => (
        <ItemWithDeleteOption
          key={index}
          onClick={() => {
            const newItems = items.filter((i) => i !== item);
            setItems(newItems);
          }}
          name={item}
        />
      ))}

      <Controller
        name={formKey}
        control={control}
        render={() => (
          <>
            {!isPanelOpen ? (
              <button
                type="button"
                className="btn btn-link btn-sm text-decoration-none fw-bold"
                data-testid="add-new-item-button"
                disabled={isDisabled}
                onClick={() => {
                  setIsPanelOpen(true);
                }}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  className="text-small "
                  size="sm"
                />
                Add New {label}
              </button>
            ) : (
              <AddNewItem
                errors={items.length ? [...errorsInput] : []}
                setValue={(value) => {
                  setNewItemValue(value);
                }}
                placeholder={`Enter ${label}`}
                value={newItemValue}
                onSave={handleOnSave}
                isDisabled={isDisabled}
              />
            )}
          </>
        )}
        rules={{
          required: {
            value: required ? true : false,
            message: `${label} field is required`,
          },
        }}
      />
      {!items.length && validationBudge}
    </div>
  );
};

export default InteractiveItemList;
