import { FieldErrorsImpl, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import ErrorBadge from '../ErrorBadge/ErrorBadge';
import { ValuesType } from 'state/forms/forms.reducer';
import { FIELD_MISSING, VALUE_TOO_SHORT } from 'constants/validationMessages';
import FormField from '../../containers/FormField/FormField';
import { useAppSelector } from 'hooks/useRedux';
import { formsActions, formsSelectors } from 'state/forms';
import { useAppDispatch } from 'state/store';

interface singleRadioProps {
  item: string;
  value?: string | number;
  register: UseFormRegister<ValuesType>;
  formKey: string;
  required?: boolean;
  identifierForOther?: string;
  showIndicationInput?: boolean;
  setValue?: UseFormSetValue<ValuesType>;
  registerForm?: boolean;
  isDisabled?: boolean;
}

export interface radioButtonProps {
  form: {
    id: number;
    options: string[];
    title: (required: boolean | undefined) => JSX.Element;
  };
  required?: boolean;
  register: UseFormRegister<ValuesType>;
  errors?: Partial<
    FieldErrorsImpl<{
      [x: string]: NonNullable<string | string[]>;
    }>
  >;
  formKey: string;
  identifierForOther?: string;
  showIndicationInput?: boolean;
  setValue?: UseFormSetValue<ValuesType>;
  registerForm?: boolean;
  isDisabled?: boolean;
}

const SingleRadio = ({
  item,
  register,
  formKey,
  required,
  identifierForOther,
  showIndicationInput,
  setValue,
  registerForm,
  isDisabled,
}: singleRadioProps) => {
  const showOtherInput = showIndicationInput && identifierForOther && item === 'Other';
  const defaultLocationKey = useAppSelector(formsSelectors.defaultLocationKeySelector);
  const dispatch = useAppDispatch();

  const defaultLocation = () => {
    if (formKey.includes('provider:location'))
      if (setValue && defaultLocationKey !== formKey) {
        setValue(defaultLocationKey, false);
        dispatch(formsActions.setDefaultLocationKey(formKey));
      } else {
        dispatch(formsActions.setDefaultLocationKey(formKey));
      }
  };

  return (
    <div className={'radio'}>
      <input
        className="form-check-input radio-btn "
        type="radio"
        value={item}
        id={registerForm ? formKey : item}
        disabled={isDisabled}
        {...register(formKey, {
          onChange: registerForm ? defaultLocation : undefined,
          required: {
            value: required ? true : false,
            message: FIELD_MISSING,
          },
        })}
      />
      <label
        className="form-check-label"
        htmlFor={registerForm ? formKey : item}
      >
        {item}
        {showOtherInput && (
          <div className="d-inline-block ms-2">
            <FormField
              type="text"
              identifier={identifierForOther}
              validation={{
                minLength: {
                  value: 5,
                  message: VALUE_TOO_SHORT,
                },
              }}
            />
          </div>
        )}
      </label>
    </div>
  );
};

const RadioButton = ({
  form,
  required,
  register,
  formKey,
  errors,
  identifierForOther,
  showIndicationInput,
  setValue,
  registerForm,
  isDisabled,
}: radioButtonProps) => {
  const { options, title, id } = form;

  const redBorderError =
    errors && errors[formKey] && 'border border-danger border-opacity-25 border-2 rounded';

  const validationBudge = errors && errors[formKey] && (
    <ErrorBadge
      errors={errors}
      formKey={formKey}
    />
  );

  return (
    <>
      {!registerForm && title(required)}
      <span
        className={`${redBorderError} p-1`}
        data-testid="radiobutton"
      >
        {options.map((item, index) => {
          return (
            <SingleRadio
              setValue={setValue}
              required={required}
              formKey={formKey}
              register={register}
              key={id + index}
              item={item}
              identifierForOther={identifierForOther}
              showIndicationInput={showIndicationInput}
              registerForm={registerForm}
              isDisabled={isDisabled}
            />
          );
        })}
      </span>
      {validationBudge}
    </>
  );
};

export default RadioButton;
