import InputMask from 'react-input-mask';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import ErrorBadge from '../ErrorBadge/ErrorBadge';
import dayjs from 'dayjs';
import { ValuesType } from 'state/forms/forms.reducer';
import { FIELD_MISSING } from 'constants/validationMessages';

dayjs.extend(customParseFormat);

interface props {
  title: string;
  required: boolean;
  register: UseFormRegister<ValuesType>;
  formKey: string;
  validation?: object;
  isDisabled?: boolean;
  errors?: Partial<
    FieldErrorsImpl<{
      [x: string]: NonNullable<string | string[]>;
    }>
  >;
}

const DateField = ({
  title,
  required,
  register,
  formKey,
  errors,
  validation,
  isDisabled,
}: props) => {
  const redBorderError =
    errors && formKey && errors[formKey] && 'border border-danger border-opacity-25 border-2';

  const validationBudge = errors && errors[formKey] && (
    <ErrorBadge
      errors={errors}
      formKey={formKey}
    />
  );

  return (
    <div
      className="col-sm-6 col-md-3"
      data-testid="date-field"
    >
      <label>
        {title}
        {required && <span className="text-danger">*</span>}
      </label>
      <div className="input-group">
        <InputMask
          {...register(formKey, {
            required: {
              value: required,
              message: FIELD_MISSING,
            },
            ...validation,
          })}
          className={`form-control ${redBorderError}`}
          placeholder="MM/DD/YYYY"
          mask="99/99/9999"
          disabled={isDisabled}
        />
        <span className="input-group-text">
          <FontAwesomeIcon icon={faCalendar} />
        </span>
      </div>
      {validationBudge}
    </div>
  );
};

export default DateField;
