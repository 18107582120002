import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import type { ValuesType } from 'state/forms/forms.reducer';

export interface Props {
  placeholder?: string;
  required?: boolean;
  title?: string;
  style?: React.CSSProperties;
  formKey?: string;
  register: UseFormRegister<ValuesType>;
  isDisabled?: boolean;
}

const TextareaField = ({
  title,
  required,
  placeholder,
  style,
  formKey,
  register,
  isDisabled,
}: Props) => {
  return (
    <div className="form-control wrapper-textarea small">
      <label
        data-testid="label"
        className="second-heading"
      >
        {title}
        {required && <span className="text-danger">*</span>}
      </label>
      <textarea
        {...(formKey && {
          ...register(formKey, { required }),
        })}
        rows={3}
        data-testid="textarea"
        className="textarea-style"
        placeholder={placeholder}
        style={style}
        disabled={isDisabled}
      ></textarea>
    </div>
  );
};

export default TextareaField;
