import { useLocation, useNavigate } from 'react-router-dom';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'hooks/useRedux';
import { authActions } from 'state/auth';
import NameHeader from 'components/nameHeader/NameHeader';
import FormWrapper from 'components/FormWrapper/FormWrapper';

const SuccessfulRegistration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const identifier = location.state?.identifier;

  const handleClick = () => {
    dispatch(authActions.loggingOut());
    navigate('/');
  };

  if (!identifier) {
    navigate('/register');
    return null;
  }

  return (
    <>
      <NameHeader serviceType={false} />
      <div className="container mt-2">
        <FormWrapper
          text={`Step 1 of 4: Initial Onboarding`}
          type="provider"
        >
          <div className="row p-3 text-center">
            <div className="col-12">
              <div className="fa-6x">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="text-success"
                />
              </div>
              <h5 className="text-success">Onboarding Initiated</h5>
              <div className="col-12">
                <div>
                  You will receive an email to complete your identity proofing shortly. In case you
                  do not see the email, please check your Spam folder.
                </div>
                <div>
                  If you do not receive the email with 24 hours,{' '}
                  <a
                    href="https://drfirsthelp1649968111jazz.zendesk.com"
                    target="_blank"
                  >
                    chat with us
                  </a>{' '}
                  or contact support at (855) 863-1355. Please have your NPI and email address
                  ready.
                </div>
                <a
                  href="#"
                  className="btn btn-primary my-2"
                  onClick={handleClick}
                >
                  Return to Login
                </a>
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    </>
  );
};

export default SuccessfulRegistration;
