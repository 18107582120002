import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePrescription, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { useAppDispatch } from 'hooks/useRedux';
import { workflowActions } from 'state/workflow';
import FormWrapper from 'components/FormWrapper/FormWrapper';

const UnsignedPrescription = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(workflowActions.setLoading(false));
  }, []);

  const onCompletePrescriptionClick = () => {
    dispatch(workflowActions.setLoading(true));
    dispatch(
      workflowActions.workflowNextStep({
        option: 'relaunch_rcopia',
      }),
    );
  };

  const onCancelTransactionClick = async () => {
    dispatch(workflowActions.setLoading(true));
    dispatch(
      workflowActions.workflowNextStep({
        option: 'cancel_transaction',
      }),
    );
  };

  return (
    <>
      <FormWrapper text="Complete Prescription">
        <div className="row p-3 text-center">
          <div className="col-12">
            <div className="fa-6x">
              <FontAwesomeIcon
                icon={faEdit}
                className="text-warning"
              />
            </div>
            <h5>Your prescription is still pending and has not been sent to the pharmacy</h5>
            <div className="col-12 text-center">
              <button
                className="btn btn-primary m-1"
                onClick={onCompletePrescriptionClick}
              >
                <FontAwesomeIcon
                  icon={faFilePrescription}
                  color="white"
                />
                &nbsp;Complete Prescription
              </button>
              <button
                onClick={onCancelTransactionClick}
                className="btn btn-warning m-1"
              >
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  color="white"
                />
                &nbsp;Cancel Transaction
              </button>
            </div>
          </div>
        </div>
      </FormWrapper>
    </>
  );
};

export default UnsignedPrescription;
