import { useLocation } from 'react-router-dom';
import { REMS_RESEND_IDP_INVITE_SUCCESS } from 'constants/identifierAndActions';
import SuccessComponent from 'components/SuccessComponent/SuccessComponent';
import IDPForm from './IDPForm';
import NameHeader from 'components/nameHeader/NameHeader';
import ProgressBar from 'components/Progress/ProgressBar';
import LoadingContent from 'components/LoadingContent/LoadingContent';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import { useEffect } from 'react';
import { useAppSelector } from 'hooks/useRedux';
import { workflowActions, workflowSelectors } from 'state/workflow';
import { DO_NOT_WORRY } from 'constants/validationMessages';
import { useAppDispatch } from 'state/store';

const IDP = () => {
  const location = useLocation();
  const identifier = location.state?.identifier;
  const isLoading = useAppSelector(workflowSelectors.loadingSelector);
  const dispatch = useAppDispatch();

  const error = useAppSelector(workflowSelectors.errorSelector);

  useEffect(() => {
    dispatch(workflowActions.setLoading(false));
  }, [error]);

  if (!identifier) {
    return null;
  }

  const stepSelector = 1;

  const renderForm = () => {
    if (identifier === REMS_RESEND_IDP_INVITE_SUCCESS) {
      return <SuccessComponent title={'IDP invite has been sent'} />;
    }

    return <IDPForm identifier={identifier} />;
  };

  return (
    <>
      <NameHeader
        serviceType={false}
        signOut
      />
      <div className="container mt-2">
        <ProgressBar
          steps={[
            { id: 0, label: 'Initial Onboarding' },
            { id: 1, label: 'Identity Proofing' },
            { id: 2, label: 'EPCS Registration' },
            { id: 3, label: 'Logical Access Control (LAC)' },
          ]}
          activeStep={stepSelector}
        />
      </div>
      <div className="container mt-2">
        <div className="row g-o">
          <FormWrapper
            text={`Step ${stepSelector + 1} of 4: Identity Proofing`}
            type="provider"
            errorSubTitle={DO_NOT_WORRY}
          >
            {!isLoading ? renderForm() : <LoadingContent />}
          </FormWrapper>
        </div>
      </div>
    </>
  );
};

export default IDP;
