import { FormEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { authActions, authSelectors } from 'state/auth';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import Alert from 'react-bootstrap/Alert';
import { VALUE_TOO_LONG, INVALID_EMAIL, FIELD_MISSING } from 'constants/validationMessages';

import ErrorBadge from 'components/ErrorBadge/ErrorBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { emailRegex } from 'constants/validationRules';

interface DefaultValues {
  email: string;
}

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoader, setIsLoader] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const defaultValues = { email: '' };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const handleReturnToLoginClick = (event: FormEvent) => {
    event.preventDefault();
    navigate('/');
    dispatch(authActions.setForgotPasswordError(''));
  };

  const error = useAppSelector(authSelectors.forgotPasswordErrorSelector);

  const onSubmit = async (data: DefaultValues) => {
    setIsLoader(true);
    await dispatch(authActions.forgotPassword({ email: data.email }));

    if (!error) {
      setShowMessage(true);
    }
    setIsLoader(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-rems w-100 d-flex ">
        <div className="container pt-5 mt-5">
          <div className="col-10 offset-1 col-md-4 offset-md-4">
            <div className="card mb-2 shadow">
              <div className="card-header bg-info text-white fw-bolder text-center">
                <img
                  src={require('./../../../assets/logo/jazz_logo_white.png')}
                  className="img-fluid d-inline-block align-text-middle"
                />
                <div className="pt-3">
                  <h5 className="fw-bold">FORGOT PASSWORD</h5>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="row px-3 py-1">
                  <div className="col-12">
                    Forgot your password? Enter your email address below to receive an email with
                    resetting instructions.
                  </div>
                  <div className="col-12 mt-2">
                    {showMessage && !error && <Alert variant={'success'}>Email was sent</Alert>}
                    {error && <Alert variant={'danger'}>{error}</Alert>}
                    <label>
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={showMessage && !error}
                      type="text"
                      className="form-control"
                      {...register('email', {
                        required: {
                          value: true,
                          message: FIELD_MISSING,
                        },
                        pattern: {
                          value: emailRegex,
                          message: INVALID_EMAIL,
                        },
                        maxLength: {
                          value: 100,
                          message: VALUE_TOO_LONG,
                        },
                      })}
                    />
                    <ErrorBadge
                      errors={errors}
                      formKey="email"
                    />
                  </div>
                  <div className="col-12 mt-2 text-center">
                    <button
                      className="btn btn-primary"
                      disabled={showMessage && !error}
                    >
                      Submit
                      {isLoader && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          color="white"
                          className="mx-1"
                          spinPulse={true}
                        />
                      )}
                    </button>
                  </div>
                </div>
                <div className="col-12 my-2 text-center">
                  <a
                    href="REMS - Login.html"
                    onClick={handleReturnToLoginClick}
                  >
                    Return to Login
                  </a>
                </div>
              </div>
              <div className="row text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ForgotPassword;
