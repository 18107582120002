import { useState } from 'react';
import { faCircleCheck, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormWrapper from 'components/FormWrapper/FormWrapper';
import NameHeader from 'components/nameHeader/NameHeader';
import ProgressBar from 'components/Progress/ProgressBar';
import InfoBar from 'components/InfoBar/InfoBar';
import { Button, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { workflowActions, workflowSelectors } from 'state/workflow';
import { useLocation } from 'react-router-dom';
import Loader from 'components/Loader/Loader';
import { DO_NOT_WORRY } from 'constants/validationMessages';
import { authSelectors } from 'state/auth';

const EPCSEnrollment = () => {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useAppDispatch();

  const user = useAppSelector(authSelectors.userSelector);

  const isLoading = useAppSelector(workflowSelectors.loadingSelector);

  const stepSelector = 2;

  const handleClose = () => {
    setShowModal(false);
    dispatch(workflowActions.workflowNextStep());
  };

  const Values: { completeEPCSRegistrationLink: string } | undefined = useAppSelector(
    workflowSelectors.formValuesSelector,
  );

  const EPCSLink = Values?.completeEPCSRegistrationLink || '';

  const location = useLocation();

  const identifier = location.state?.identifier;

  if (!identifier) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Modal
        fullscreen="md-down"
        dialogClassName="modal-xl"
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header className="bg-dark-modal pl-1 pr-1 pt-0 pb-0 m-0">
          <Button
            variant="none"
            className="btn-modal-close text-light"
            onClick={handleClose}
          >
            ×
          </Button>
        </Modal.Header>
        <iframe
          src={EPCSLink}
          style={{ width: '100%', height: '80vh' }}
        ></iframe>
      </Modal>
      <NameHeader
        serviceType={false}
        signOut
      />
      <div className="container mt-2">
        <ProgressBar
          steps={[
            { id: 0, label: 'Initial Onboarding' },
            { id: 1, label: 'Identity Proofing' },
            { id: 2, label: 'EPCS Registration' },
            { id: 3, label: 'Logical Access Control (LAC)' },
          ]}
          activeStep={stepSelector}
        />
        <div className="row g-o">
          <FormWrapper
            text={`Step ${stepSelector + 1} of 4: EPCS Registration`}
            type="provider"
            errorSubTitle={DO_NOT_WORRY}
          >
            <div className="row p-3 text-center">
              <div className="col-12">
                {user && <h4 className="text-info">{`Hello Dr. ${user.name}`}</h4>}
                <h5 className="fw-bold">You are currently on Step 3 - EPCS Registration</h5>
                <div className="fa-6x">
                  <FontAwesomeIcon
                    icon={faUserLock}
                    className="text-muted"
                  />
                </div>
                <div className="col-12">
                  <div>
                    Post successful Identity Proofing, you will either receive an SMS text message
                    or a letter from Experian through USPS. If you receive the letter from Experian
                    you will find an Experian Transaction number included in the letter. If you
                    receive the SMS text message, the text message will contain the Experian
                    Transaction number. This number is to be used for verification of the indentity
                    proofing session you completed in the previous step.
                  </div>
                  <div className="pt-3 fw-bold">
                    If you have your Experian Transaction Number, click on the link below to
                    complete the next step.
                  </div>
                  <a
                    className="btn btn-primary m-3"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faCircleCheck} /> Complete EPCS Registration
                  </a>
                </div>
              </div>
            </div>

            <InfoBar>
              If it has been more than 7 business days since you completed the Identity Proofing and
              have not received your letter containing the IDP confirmation code,{' '}
              <a
                href="https://drfirsthelp1649968111jazz.zendesk.com"
                target="_blank"
              >
                Chat with us
              </a>{' '}
              or contact support at (855) 863-1355. Please have your NPI and email address ready.{' '}
            </InfoBar>
          </FormWrapper>
        </div>
      </div>
    </>
  );
};

export default EPCSEnrollment;
