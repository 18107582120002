import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { authActions, authSelectors } from 'state/auth';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { progressBarActions } from 'state/progressBar';
import { serviceTypeActions } from 'state/serviceType';
import DefaultModal from 'components/Modal/Modal';
import { workflowActions } from 'state/workflow';

interface NameHeaderProps {
  serviceType: 'xyrem' | 'xywav' | false;
  signOut?: true;
  remsFlow?: boolean;
  disableLogoOnClick?: boolean;
}

const LOGO_SELECT_MED_TEXT =
  'You are about to be taken back to the drug selection screen. Any data entered so far will be discarded. Are you sure?';
const LOGO_BACK_TO_LOGIN =
  'You are about to be taken back to the login page. Any data entered will be discarded. Are you sure?';

type backToType = 'chooseMed' | 'logout' | '';
type showConfirmationType = {
  show: boolean;
  backTo: backToType;
};

const NameHeader = ({ serviceType, signOut, remsFlow, disableLogoOnClick }: NameHeaderProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isDisableLogo = disableLogoOnClick || (!serviceType && signOut);

  const token = useAppSelector(authSelectors.tokenSelector);

  const [showConfirmation, setShowConfirmation] = useState<showConfirmationType>({
    show: false,
    backTo: '',
  });
  const [showPdf, setShowPdf] = useState(false);

  const { show, backTo } = showConfirmation;

  const handleLogOut = async () => {
    await dispatch(authActions.logOutAndRemoveRefreshToken({ token: token }));
    dispatch(workflowActions.setError(false));
    dispatch(progressBarActions.resetStep());
    navigate('/');
  };

  const displayConfirmationModal = (backTo: backToType) => {
    setShowConfirmation({ show: true, backTo });
  };

  const displayPdf = () => {
    setShowPdf(true);
  };

  const handleReset = () => {
    if (remsFlow && backTo === 'chooseMed') {
      navigate('/workflow', {
        state: {
          reset: true,
        },
      });
    } else {
      handleLogOut();
    }

    dispatch(serviceTypeActions.setServiceType(''));
    dispatch(progressBarActions.resetStep());
  };

  const handleClose = () => {
    setShowConfirmation({ show: false, backTo: '' });
    setShowPdf(false);
  };

  const setBackTo = signOut ? 'chooseMed' : 'logout';
  const setModalText = backTo === 'chooseMed' ? LOGO_SELECT_MED_TEXT : LOGO_BACK_TO_LOGIN;

  return (
    <div className="navbar bg-info text-white">
      <DefaultModal
        handleClose={handleClose}
        handleSubmit={handleReset}
        show={show}
        text={setModalText}
        cancelButtonText="Cancel"
        continueButtonText="Yes, Continue"
      />
      <div className="container-fluid justify-content-md-between justify-content-center">
        <div className="col-md-4 col-12 text-center text-md-left justify-content-md-center">
          <div className="float-md-start">
            <img
              onClick={() => {
                if (isDisableLogo) {
                  return;
                }
                displayConfirmationModal(setBackTo);
              }}
              role={isDisableLogo ? undefined : 'button'}
              src={require('./../../assets/logo/jazz_logo_white.png')}
              className="align-text-middle d-inline-block"
            />
          </div>
        </div>
        {serviceType && (
          <div className="col-md-4 col-12 text-center justify-content-md-center">
            <div
              className={`border border-2 d-inline-block border-${serviceType} text-center p-1 bg-white`}
            >
              <img
                data-testid={serviceType}
                src={require(`./../../assets/logo/${serviceType}.jpg`)}
                height="40"
              />
            </div>
            <div>
              <a
                onClick={displayPdf}
                id="show-pdf"
                className="text-white btn btn-link p-0"
              >
                Prescribing&nbsp;Information
              </a>
            </div>
            <Modal
              className="modal-xl modal-dialog-centered "
              show={showPdf}
              onHide={handleClose}
            >
              <Modal.Header className="bg-dark-modal pl-1 pr-1 pt-0 pb-0 m-0">
                <Modal.Title className="mb-0">
                  <h4>{serviceType && serviceType.toUpperCase()} REMS</h4>
                </Modal.Title>
                <Button
                  variant="none"
                  className="btn-modal-close text-light"
                  onClick={handleClose}
                >
                  ×
                </Button>
              </Modal.Header>
              <iframe
                src={`https://pp.jazzpharma.com/pi/${serviceType}.en.USPI.pdf`}
                style={{ width: '100%', height: '80vh' }}
              ></iframe>
            </Modal>
          </div>
        )}
        {signOut && (
          <div className="col-md-4 col-12 text-center justify-content-md-center">
            <div className="float-md-end">
              <button
                data-testid="logout-button"
                className="btn btn-link text-white"
                onClick={() => displayConfirmationModal('logout')}
              >
                Sign out
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NameHeader;
